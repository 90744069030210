// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/luci/auth_service/api/rpcpb/authdb.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../../google/protobuf/timestamp.pb";
import { Principal } from "./groups.pb";

export const protobufPackage = "auth.service";

/** GetSnapshotRequest is passed to GetSnapshot rpc. */
export interface GetSnapshotRequest {
  /**
   * Version of the snapshot to get or 0 to get the latest one.
   * Not all versions may be available (i.e. there may be gaps in revision
   * numbers).
   */
  readonly revision: string;
  /**
   * If true response will not return actual snapshot, just its SHA256
   * hash, revision number and timestamp.
   */
  readonly skipBody: boolean;
}

/**
 * Snapshot contains information about a specific AuthDB revision.
 * The revision number is used to identify this snapshot.
 */
export interface Snapshot {
  /** Revision number of this Snapshot. */
  readonly authDbRev: string;
  /** SHA256 hash for this Snapshot. */
  readonly authDbSha256: string;
  /** Deflated AuthDB proto message, omitted if skip_body is true. */
  readonly authDbDeflated: Uint8Array;
  /** Time that this Snapshot was created. */
  readonly createdTs: string | undefined;
}

/**
 * GetPrincipalPermissionsRequest is to specify any principal (group, user, blob) for
 * which permissions should be fetched.
 */
export interface GetPrincipalPermissionsRequest {
  readonly principal: Principal | undefined;
}

/** PrincipalPermissions specifies a list of realm permissions for a single principal. */
export interface PrincipalPermissions {
  readonly realmPermissions: readonly RealmPermissions[];
  /** Principal that was used for querying permissions, e.g. "group:administrators". */
  readonly name: string;
}

/** RealmPermissions specifies a realm name and the permissions a principal has in it. */
export interface RealmPermissions {
  readonly name: string;
  readonly permissions: readonly string[];
}

function createBaseGetSnapshotRequest(): GetSnapshotRequest {
  return { revision: "0", skipBody: false };
}

export const GetSnapshotRequest: MessageFns<GetSnapshotRequest> = {
  encode(message: GetSnapshotRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.revision !== "0") {
      writer.uint32(8).int64(message.revision);
    }
    if (message.skipBody !== false) {
      writer.uint32(16).bool(message.skipBody);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetSnapshotRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSnapshotRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.revision = reader.int64().toString();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.skipBody = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSnapshotRequest {
    return {
      revision: isSet(object.revision) ? globalThis.String(object.revision) : "0",
      skipBody: isSet(object.skipBody) ? globalThis.Boolean(object.skipBody) : false,
    };
  },

  toJSON(message: GetSnapshotRequest): unknown {
    const obj: any = {};
    if (message.revision !== "0") {
      obj.revision = message.revision;
    }
    if (message.skipBody !== false) {
      obj.skipBody = message.skipBody;
    }
    return obj;
  },

  create(base?: DeepPartial<GetSnapshotRequest>): GetSnapshotRequest {
    return GetSnapshotRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetSnapshotRequest>): GetSnapshotRequest {
    const message = createBaseGetSnapshotRequest() as any;
    message.revision = object.revision ?? "0";
    message.skipBody = object.skipBody ?? false;
    return message;
  },
};

function createBaseSnapshot(): Snapshot {
  return { authDbRev: "0", authDbSha256: "", authDbDeflated: new Uint8Array(0), createdTs: undefined };
}

export const Snapshot: MessageFns<Snapshot> = {
  encode(message: Snapshot, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.authDbRev !== "0") {
      writer.uint32(8).int64(message.authDbRev);
    }
    if (message.authDbSha256 !== "") {
      writer.uint32(18).string(message.authDbSha256);
    }
    if (message.authDbDeflated.length !== 0) {
      writer.uint32(26).bytes(message.authDbDeflated);
    }
    if (message.createdTs !== undefined) {
      Timestamp.encode(toTimestamp(message.createdTs), writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Snapshot {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSnapshot() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.authDbRev = reader.int64().toString();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.authDbSha256 = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.authDbDeflated = reader.bytes();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.createdTs = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Snapshot {
    return {
      authDbRev: isSet(object.authDbRev) ? globalThis.String(object.authDbRev) : "0",
      authDbSha256: isSet(object.authDbSha256) ? globalThis.String(object.authDbSha256) : "",
      authDbDeflated: isSet(object.authDbDeflated) ? bytesFromBase64(object.authDbDeflated) : new Uint8Array(0),
      createdTs: isSet(object.createdTs) ? globalThis.String(object.createdTs) : undefined,
    };
  },

  toJSON(message: Snapshot): unknown {
    const obj: any = {};
    if (message.authDbRev !== "0") {
      obj.authDbRev = message.authDbRev;
    }
    if (message.authDbSha256 !== "") {
      obj.authDbSha256 = message.authDbSha256;
    }
    if (message.authDbDeflated.length !== 0) {
      obj.authDbDeflated = base64FromBytes(message.authDbDeflated);
    }
    if (message.createdTs !== undefined) {
      obj.createdTs = message.createdTs;
    }
    return obj;
  },

  create(base?: DeepPartial<Snapshot>): Snapshot {
    return Snapshot.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Snapshot>): Snapshot {
    const message = createBaseSnapshot() as any;
    message.authDbRev = object.authDbRev ?? "0";
    message.authDbSha256 = object.authDbSha256 ?? "";
    message.authDbDeflated = object.authDbDeflated ?? new Uint8Array(0);
    message.createdTs = object.createdTs ?? undefined;
    return message;
  },
};

function createBaseGetPrincipalPermissionsRequest(): GetPrincipalPermissionsRequest {
  return { principal: undefined };
}

export const GetPrincipalPermissionsRequest: MessageFns<GetPrincipalPermissionsRequest> = {
  encode(message: GetPrincipalPermissionsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.principal !== undefined) {
      Principal.encode(message.principal, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetPrincipalPermissionsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPrincipalPermissionsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.principal = Principal.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPrincipalPermissionsRequest {
    return { principal: isSet(object.principal) ? Principal.fromJSON(object.principal) : undefined };
  },

  toJSON(message: GetPrincipalPermissionsRequest): unknown {
    const obj: any = {};
    if (message.principal !== undefined) {
      obj.principal = Principal.toJSON(message.principal);
    }
    return obj;
  },

  create(base?: DeepPartial<GetPrincipalPermissionsRequest>): GetPrincipalPermissionsRequest {
    return GetPrincipalPermissionsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetPrincipalPermissionsRequest>): GetPrincipalPermissionsRequest {
    const message = createBaseGetPrincipalPermissionsRequest() as any;
    message.principal = (object.principal !== undefined && object.principal !== null)
      ? Principal.fromPartial(object.principal)
      : undefined;
    return message;
  },
};

function createBasePrincipalPermissions(): PrincipalPermissions {
  return { realmPermissions: [], name: "" };
}

export const PrincipalPermissions: MessageFns<PrincipalPermissions> = {
  encode(message: PrincipalPermissions, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.realmPermissions) {
      RealmPermissions.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PrincipalPermissions {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrincipalPermissions() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.realmPermissions.push(RealmPermissions.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrincipalPermissions {
    return {
      realmPermissions: globalThis.Array.isArray(object?.realmPermissions)
        ? object.realmPermissions.map((e: any) => RealmPermissions.fromJSON(e))
        : [],
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: PrincipalPermissions): unknown {
    const obj: any = {};
    if (message.realmPermissions?.length) {
      obj.realmPermissions = message.realmPermissions.map((e) => RealmPermissions.toJSON(e));
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<PrincipalPermissions>): PrincipalPermissions {
    return PrincipalPermissions.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PrincipalPermissions>): PrincipalPermissions {
    const message = createBasePrincipalPermissions() as any;
    message.realmPermissions = object.realmPermissions?.map((e) => RealmPermissions.fromPartial(e)) || [];
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseRealmPermissions(): RealmPermissions {
  return { name: "", permissions: [] };
}

export const RealmPermissions: MessageFns<RealmPermissions> = {
  encode(message: RealmPermissions, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    for (const v of message.permissions) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RealmPermissions {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRealmPermissions() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.permissions.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RealmPermissions {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      permissions: globalThis.Array.isArray(object?.permissions)
        ? object.permissions.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: RealmPermissions): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.permissions?.length) {
      obj.permissions = message.permissions;
    }
    return obj;
  },

  create(base?: DeepPartial<RealmPermissions>): RealmPermissions {
    return RealmPermissions.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RealmPermissions>): RealmPermissions {
    const message = createBaseRealmPermissions() as any;
    message.name = object.name ?? "";
    message.permissions = object.permissions?.map((e) => e) || [];
    return message;
  },
};

/** AuthDB contains methods to work with AuthDB. */
export interface AuthDB {
  /**
   * GetSnapshot serves the deflated AuthDB proto
   * message with snapshot of all groups.
   */
  GetSnapshot(request: GetSnapshotRequest): Promise<Snapshot>;
  /** GetPermissions returns all permissions the given principal has within each realm. */
  GetPrincipalPermissions(request: GetPrincipalPermissionsRequest): Promise<PrincipalPermissions>;
}

export const AuthDBServiceName = "auth.service.AuthDB";
export class AuthDBClientImpl implements AuthDB {
  static readonly DEFAULT_SERVICE = AuthDBServiceName;
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || AuthDBServiceName;
    this.rpc = rpc;
    this.GetSnapshot = this.GetSnapshot.bind(this);
    this.GetPrincipalPermissions = this.GetPrincipalPermissions.bind(this);
  }
  GetSnapshot(request: GetSnapshotRequest): Promise<Snapshot> {
    const data = GetSnapshotRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetSnapshot", data);
    return promise.then((data) => Snapshot.fromJSON(data));
  }

  GetPrincipalPermissions(request: GetPrincipalPermissionsRequest): Promise<PrincipalPermissions> {
    const data = GetPrincipalPermissionsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetPrincipalPermissions", data);
    return promise.then((data) => PrincipalPermissions.fromJSON(data));
  }
}

interface Rpc {
  request(service: string, method: string, data: unknown): Promise<unknown>;
}

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
